import { Component, Input } from '@angular/core';
import { env } from '../../../environments/environment';

@Component({
  selector: 'app-maps-link',
  templateUrl: './maps-link.component.html',
  styleUrls: ['./maps-link.component.scss']
})
export class MapsLinkComponent {
  @Input() latitude: number = env.maps.latitude;
  @Input() longitude: number = env.maps.longitude;
  @Input() pointOfInterest: string = env.maps.pointOfInterest;

  get googleMapsLink(): string {
    return `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;
  }

  get googleMapsImgUrl(): string {
    const apiKey = env.maps.apiKey;
    return `https://maps.googleapis.com/maps/api/staticmap?` +
    `center=${this.latitude},${this.longitude}&zoom=14&size=600x300&maptype=roadmap&` + 
    `markers=color:green%7Clabel:${this.pointOfInterest.charAt(0)}%7C${this.latitude},${this.longitude}&maptype=hybrid&key=${apiKey}`;
    // return `https://maps.googleapis.com/maps/api/staticmap?` +
    // `center=${this.latitude},${this.longitude}&zoom=14&size=600x300&maptype=roadmap&` + 
    // `markers=color:blue%7Clabel:${this.pointOfInterest.charAt(0)}%7C${this.latitude},${this.longitude}&key=${apiKey}`;
  }
}
