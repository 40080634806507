<div class="map-container">
  <!-- Embedded map photo as a clickable link -->
  <a [href]="googleMapsLink" target="_blank" rel="noopener noreferrer">
    <img [src]="googleMapsImgUrl" alt="Find us on Google Maps at the {{pointOfInterest}}" class="map-image" />
  </a>
  <!-- Text link -->
  <p>
    <a [href]="googleMapsLink" target="_blank" rel="noopener noreferrer" class="text-link">
      Open in Google Maps
    </a>
  </p>
</div>