<div class="about-us-container">
  <!-- Sidebar -->
  <aside class="sidebar">
    <nav>
      <ul class="sidebar-nav">
        <li class="team-member-first"><a (click)="scrollTo('bill')">William H. Fric</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('howard')">Howard M. Lowenstein</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('anthony')">Anthony J. Di Lello</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('tami')">Tami Fric</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('york')">York Campbell</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('marc')">Marc Lowenstein</a></li>
        <hr>
        <li class="team-member-last"><a (click)="scrollTo('tracy')">Tracy Hildenbrand</a></li>
      </ul>
    </nav>
  </aside>
  <div class="page-container">
    <h3 class="about-us-banner">About Us</h3>
    <h4 class="about-us-sub-banner">About Us: Classmates and Partners</h4>
    <blockquote class="page-writing">
      After graduating together in 1976, William Fric and Howard Lowenstein committed themselves to 
      serving the legal needs of their neighbours. Since 1982, Fric, Lowenstein & Co. LLP has offered 
      prudent legal advice to citizens in Calgary and throughout Alberta. When you choose 
      Fric, Lowenstein & Co. LLP, you put 5 lawyers and over 30 years of legal expertise to work for you. 
      We offer a range of legal services for individuals as well as business owners in the province and our 
      committed staff is ready to offer you first-rate legal representation. Call us today to speak to our team.  <br><br>
      <strong>Meet Our Staff</strong>  <br><br>
      Choosing a lawyer is a weighty decision. Learn more about the staff of Fric, Lowenstein & Co. LLP here.<br><br>
    </blockquote>
    <hr>
    <!--START OF BILL-->
    <div class="profile-section">
      <div class="profile-content" id="bill">
        <img src="../../../../assets/site/headshots/fric/fricv2.jpg" alt="Bill Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing" >
          <span class="member-name"><strong>William H. Fric, JD</strong></span> <br><br>
          <span class="member-intro">
            EDUCATION: University of Winnipeg BA (Economics) 1972; University of Manitoba LL.B 1976  <br><br>
            CALL TO THE BAR: Alberta 1977  <br><br>
            PRACTICE PROFILE: Bill Fric’s practice comprises a full range of business transactions, 
            banking (including lending, foreclosures, and general bank issues), real estate, 
            and wills and estates. <br><br>
            Bill has been selected as external Alberta counsel for Bank of Montreal. 
            He is also approved to act for all well-known Canadian banks and financial 
            institutions. Bill acts as the sole legal counseller to a number of residential home builders, 
            and also for real estate brokerage companies. Throughout his career, Bill has acted for businesses 
            in the agricultural industry, including as sole Alberta counsel for Feed-Rite, and as sole Alberta 
            counsel to the Credit Department of Viterra (formerly Alberta Wheat Pool and United Grain Growers Limited).
            Bill derives particular enjoyment from acting for small business people; in acting for 
            young people starting out with their first home purchases; and in acting for several 
            generations of the same families. <br><br>
          </span><div class="spacer"></div>
          <strong>PROFESSIONAL ASSOCIATION</strong> <br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Member Canadian Bar Association (CBA)
            </mat-list-item>
            <mat-list-item class="property-list-item">
              2012 – Chairman of the CBA Alberta Law Conference Real Estate Panel
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Past Executive Member and current member CBA Foreclosure Subsection
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Past Executive member and current member CBA Real Property Subsection
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Current member CBA Wills and Estates Subsection
            </mat-list-item>
          </mat-list>
          <strong>COMMUNITY INVOLVEMENT</strong><br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Rotary International – Paul Harris Fellowship
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Holy Spirit Parish – Past Board Member
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Inn from the Cold – Ongoing volunteer
            </mat-list-item>
          </mat-list>
        </blockquote>
      </div>
    </div>
    <!--END OF BILL-->
    <hr>
    <!--START OF HOWIE-->
    <div class="profile-section">
      <div class="profile-content" id="howard">
        <img src="../../../../assets/site/headshots/lowenstein/lowensteinv2.jpg" alt="Howard Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing">
          <span class="member-name"><strong>Howard M. Lowenstein</strong></span> <br><br>
          <span class="member-intro">
            EDUCATION: University of Manitoba, B.A (Economics) (Honors).; 
            University of Manitoba, LL.B. 1976. <br><br>
            CALL TO THE BAR: 1977 <br><br>
            PRACTICE PROFILE: Howard Lowenstein graduated from the University of Manitoba, Faculty of Law in 1976. 
            Howard practised in Winnipeg, Manitoba until December 1979 and then moved to Calgary to practice with 
            the law firm of Sinclair McGeough Lilburn. In 1982 he joined with Bill Fric and the present firm of 
            Fric, Lowenstein & Co. has continued since that date. Howard’s primary focus is in the area of both 
            residential and commercial real estate and mortgages. Howard represents a number of major new home 
            builders and condominium developers in Calgary. Howard’s practice also includes foreclosures, wills 
            and estates, corporate and commercial transactions, debt collection matters, personal injury and 
            general civil litigation. <br><br>
          </span><div class="spacer"></div>
          <strong>PROFESSIONAL ASSOCIATION</strong> <br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Member of Canadian Bar Association (CBA)
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Past Lecturer in Real Estate Law at Bar Admission Course
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Legal Column Contributor for Calgary Real Estate News
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Member CBA Real Property Subsection
            </mat-list-item>
          </mat-list>
          <strong>COMMUNITY INVOLVEMENT</strong><br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Past President Beth Tzedec Congregation
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Past President University of Calgary Swim Club
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Past President of Swim AB Southern Region
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Swimming Canada Referee
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Calgary Special Olympics Volunteer Referee
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Past President Crohns & Colitis Foundation
            </mat-list-item>
          </mat-list>
        </blockquote>
      </div>
    </div>
    <!--END OF HOWIE-->
    <hr>
    <!--START OF ANTHONY-->
    <div class="profile-section">
      <div class="profile-content" id="anthony">
        <img src="../../../../assets/site/headshots/anthony/anthonyv2.jpg" alt="Anthony Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing">
          <span class="member-name"><strong>Anthony J. Di Lello</strong></span> <br><br>
          <span class="member-intro">
            EDUCATION: University of Calgary, B.A. (with distinction) 1992; 
            University of Alberta, LL.B. 1995
            Bachelor of Commerce with Distinction, and University of Alberta (2008). <br><br>
            CALL TO THE BAR: Alberta 1996  <br><br>
            PRACTICE PROFILE: Anthony J. Di Lello joined Fric, Lowenstein & Co. LLP in 1997 and he represents 
            clients with diverse legal issues. His law practice is primarily devoted to civil litigation with a 
            particular emphasis in the areas of estate litigation, commercial and residential foreclosures, secured 
            and unsecured realization and builders’ liens. He calls on his skills as a solicitor in the areas of wills 
            and estates, commercial and residential real estate and business transactions. Anthony successfully 
            represents clients in all levels of Alberta Courts. His clientele ranges from major financial institutions 
            and home builders to small businesses and individuals. Anthony has been lead counsel in several 
            important and leading decisions in Alberta. He uses the experiences he’s gained through decades of 
            success to custom tailor a legal solution that is cost effective and efficient for each client. <br><br>
          </span><div class="spacer"></div>
          <strong>PROFESSIONAL ASSOCIATION</strong> <br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Member of the Canadian Bar Association (CBA)
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Member of the Foreclosure, Personal Injury and Litigation subsections
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Member of the Alberta Civil Trial Lawyers Association
            </mat-list-item>
          </mat-list>
        </blockquote>
      </div>
    </div>
    <!--END OF ANTHONY-->
    <hr>
    <!--START OF TAMI-->
    <div class="profile-section">
      <div class="profile-content" id="tami">
        <img src="../../../../assets/site/headshots/tami/tami.jpg" alt="Tami Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing">
          <span class="member-name"><strong>Tami Fric</strong></span> <br><br>
          <span class="member-intro">
            EDUCATION: Juris Doctor, University of Victoria (2011), 
            Exchange Semester, University of Sydney (2010), 
            Bachelor of Commerce with Distinction, and University of Alberta (2008). <br><br>
            CALL TO THE BAR: Alberta 2012  <br><br>
            PRACTICE PROFILE: Tami Fric’s practice focuses on real estate and corporate matters. 
            Tami represents clients from first time home buyers to builders and developers. 
            Tami assists clients with commercial transactions, particularly with business purchases 
            and sales. She also acts on banking matters including lending, financing, banking and 
            foreclosures. Tami also practices in the area of wills & estates. <br>
            Prior to joining Fric, Lowenstein & Co. LLP, Tami worked at a global law firm and was 
            seconded to a multinational oil and gas corporation. <br><br>
          </span><div class="spacer"></div>
          <strong>PROFESSIONAL ASSOCIATION</strong> <br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Member of the Canadian Bar Association (CBA)
            </mat-list-item>
            <mat-list-item class="property-list-item">
              Member of the Real Estate, Wills and Estates, Business and Foreclosure subsections
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Co-Chair, Commercial Real Estate subsection
            </mat-list-item>
          </mat-list>
        </blockquote>
      </div>
    </div>
    <!--END OF TAMI-->
    <hr>
    <!--START OF YORK-->
    <div class="profile-section">
      <div class="profile-content" id="york">
        <img src="../../../../assets/site/headshots/york/yorkv2.jpg" alt="York Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing">
          <span class="member-name"><strong>York Campbell</strong></span> <br><br>
          <span class="member-intro">
            EDUCATION: University of Calgary, BCOMM (finance) 2010; 
            University of Calgary, BA (economics) 2010; 
            University of Saskatchewan, JD (2013). <br><br>
            CALL TO THE BAR: Alberta 2014 <br><br>
            PRACTICE PROFILE: York joined Fric, Lowenstein & Co. LLP in 2014 after articling in Calgary with a global firm. 
            While in law school York completed a semester exchange program and studied at Bond University in Australia. 
            York also interned for the Office of the Attorney General in Washington D.C. York’s practice comprises corporate 
            matters, business agreements and transactions, litigation, real estate, and wills and estates. <br><br>
          </span><div class="spacer"></div>
          <strong>PROFESSIONAL ASSOCIATION</strong> <br>
          <mat-list class="properties-list">
            <mat-list-item class="property-list-item-first">
              Member of the Canadian Bar Association
            </mat-list-item>
            <mat-list-item class="property-list-item-last">
              Member of the Calgary Bar Association
            </mat-list-item>
          </mat-list>
        </blockquote>
      </div>
    </div>
    <!--END OF YORK-->
    <hr>
    <!--START OF MARC-->
    <div class="profile-section">
      <div class="profile-content" id="marc">
        <img src="../../../../assets/site/headshots/marc/marcv2.jpg" alt="Marc Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing">
          <span class="member-name"><strong>Marc Lowenstein</strong></span> <br><br>
          <span class="member-intro">
            EDUCATION: University of Calgary, B.A. (Economics) 2004; 
            British Columbia Institute of Technology (Construction Management Degree Program) 2011, 
            Associate Certificates in Construction Operations and Supervision (with distinction); 
            National Construction Safety Officer 2012; Bond University, JD (Second Class Honours) 2015. <br><br>
            CALL TO THE BAR: June 2017 <br><br>
            PRACTICE PROFILE: Marc A. Lowenstein joined the firm in 2015 after completing his law 
            degree at Bond University in Australia. Before law school Marc spent more than 10 years 
            in the construction industry in various positions ranging from a labourer, foreman, 
            superintendent up to a Project Engineer for a large heavy civil construction company. 
            Marc has also worked as a National Construction Safety Officer and consultant where he built, 
            implemented and supervised health and safety programs for companies in the construction industry. 
            Marc is focusing his practice on real estate and matters relating to construction including contracts, 
            litigation and dispute resolution. Marc also recently joined the ADR Institute of Alberta and is 
            working towards becoming a Qualified Arbitrator (Q. Arb.). <br><br>
          </span><div class="spacer"></div>
        </blockquote>
      </div>
    </div>
    <!--END OF MARC-->
    <hr>
    <!--START OF TRACY-->
    <div class="profile-section">
      <div class="profile-content" id="tracy">
        <img src="../../../../assets/site/headshots/tracy/tracyv2.jpg" alt="Tracy Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing">
          <span class="member-name"><strong>Tracy Hildenbrand</strong></span> <br><br>
          <strong>Office Manager</strong>   <br><br>
          <span class="member-intro">
            Tracy obtained her legal assistant diploma in 1991, has been with our firm since 1998 and has 
            lead the amazing team at our 16 – 20 person law firm since 2015. In the time she has been here, 
            Tracy has gained experience in almost every department of our firm and prior to joining our office 
            she worked as the only staff member for small general practice lawyers so was fully responsible 
            for all aspects of the law firm including setup, reception, bookkeeping and legal assistance on a 
            large variety of files. Her past experience also includes a position as a borrowing department 
            manager for a private lender where she dealt with in depth construction mortgage loans. As well, 
            Tracy has been a licensed Realtor since 1997. <br><br>
          </span><div class="spacer"></div>
          <strong>Our Team:</strong>  Every member of our highly trained and specialized team of assistants, 
          accountants and paralegals has a minimum of 7 years all the way up to 37 years of experience with 
          most being around the 20 – 25 year mark so rest assured you are always in good and knowledgeable 
          hands from the moment we get your file until the conclusion of your matter. <br><br>
          <strong>Our Clients:</strong> Every client is extremely important to all of us and we as a team 
          pride ourselves on always ensuring quick, efficient responses, action and reporting on each 
          and every file.
        </blockquote>
      </div>
    </div>
    <!--END OF TRACY-->
  </div>
</div>

