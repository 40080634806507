import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../../services/content.service';

interface BlogArticle {
  id: string;
  title: string;
  author: string;
  date: Date;
  summary: string;
  image: string;
  topics: string[];
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  searchQuery: string = '';
  articles: BlogArticle[] = [];
  filteredArticles: BlogArticle[] = [];

  constructor(private contents: ContentService) {}

  ngOnInit(): void {
    // Initialize articles (these can later be fetched from a service)
    this.articles = [
      {
        id: '1',
        title: 'Understanding Real Estate Transactions in BC',
        author: 'John Doe',
        date: new Date('2024-01-15'),
        summary: 'A comprehensive guide to buying and selling real estate in British Columbia.',
        image: 'assets/images/real-estate-bc.jpg',
        topics: ['Real Estate', 'Transactions', 'BC']
      },
      {
        id: '2',
        title: 'How to Handle Property Disputes in Western Canada',
        author: 'Jane Smith',
        date: new Date('2024-01-25'),
        summary: 'Learn about the legal frameworks for resolving property disputes effectively.',
        image: 'assets/images/property-disputes.jpg',
        topics: ['Property', 'Disputes', 'Legal']
      },
      {
        id: '3',
        title: 'Tips for First-Time Homebuyers in Canada',
        author: 'Mary Lee',
        date: new Date('2024-02-10'),
        summary: 'Essential tips for navigating the home-buying process in Canada.',
        image: 'assets/images/homebuyers.jpg',
        topics: ['Homebuyers', 'Tips', 'Canada']
      }
    ];

    // Set the initial filtered articles
    this.filteredArticles = this.articles;
  }

  filterArticles(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredArticles = this.articles.filter(article =>
      article.topics.some(topic => topic.toLowerCase().includes(query)) ||
      article.title.toLowerCase().includes(query) ||
      article.summary.toLowerCase().includes(query)
    );
  }
}
