import { Component } from '@angular/core';

@Component({
  selector: 'app-areas-of-law',
  templateUrl: './areas-of-law.component.html',
  styleUrls: ['./areas-of-law.component.scss']
})
export class AreasOfLawComponent {

}
