<div class="page-container">
  <h3 class="contact-us-banner">Contact Us</h3>
  <h4 class="contact-us-sub-banner">Contact Us - We Help People</h4>
  <blockquote class="page-writing">
    Since 1982, individuals and businesses in Calgary and throughout the surrounding area have 
    trusted the dynamic and analytical team from Fric, Lowenstein & Co. LLP for sound legal representation. 
    Whatever your specific legal needs may be, our team and our wealth of legal resources are devoted to 
    defending your rights. <br><br>
    Come visit us at our new location #750, 11012 Macleod Trail S.E. Calgary, Alberta – Southcentre Executive Tower <br><br>
    Have a question for our team? Want to schedule a consultation? 
    Use the handy eform on this page to get in touch with 
    Fric, Lowenstein & Co. LLP today. <br><br>
    <strong>Address</strong> <br>
    #750, 11012 Macleod Trail S.E.Calgary, AB, T2J 6A5 <br><br>
    <span class="map-container">
      <app-maps-link 
        [latitude]="officeLat" 
        [longitude]="officeLong" 
        [pointOfInterest]="officeName">
      </app-maps-link>
      <!-- <img class="google-maps-office-pinned" 
      src="../../../../assets/site/googlemaps/officemap.png" 
      alt="Google Maps Pinned Location"/> -->
    </span> <br>
    <strong>Phone</strong><br>
    403-291-2594<br><br>
    <strong>Fax</strong><br>
    403-291-2668<br><br>
    <strong>Email</strong><br>
    <a class="text-link" href="mailto:friclow@gmail.com">friclow&#64;gmail.com</a> <br><br>
    <strong>Business Hours</strong><br>
    Monday – Friday, 8:30 AM – 4:30 PM; closed from noon to 1:00 PM<br><br>
    <strong>Service Area</strong><br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Calgary <br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Surrounding Area<br>
  </blockquote>
</div>