<div class="page-container">
  <h3 class="faq-banner">FAQ</h3>
  <h4 class="faq-sub-banner">Professional Legal Services in Calgary</h4>
  <blockquote class="page-writing">
    Learn more about Fric, Lowenstein & Co. LLP's Calgary-based legal practice. <br><br>
    <strong> How many years does Fric, Lowenstein & Co. LLP have? </strong> <br>
    Fric, Lowenstein & Co. LLP has more than 30 years of experience serving the legal needs of citizens in Calgary and throughout Alberta. <br><br>
    <strong>Do I have a case?</strong> <br>
    <a class="text-link" routerLink="/contact-us">Speak with Fric, Lowenstein & Co. LLP</a> today to discuss your specific situation. 
    We’re committed to defending the rights of our clients. We help people who: <br>
  </blockquote>
  <span class="bullet-point-list">
    <mat-icon class="bullet-point">chevron_right</mat-icon>Are buying or selling a property<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Are starting or operating a small business<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Are concerned about will and estate planning<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Have general legal questions<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Have been injured<br>
  </span>
  <blockquote class="page-writing">
    Learn more about Fric, Lowenstein & Co. LLP's Calgary-based legal practice. <br><br>
    <strong> How many years does Fric, Lowenstein & Co. LLP have? </strong> <br>
    Fric, Lowenstein & Co. LLP has more than 30 years of experience serving the legal needs of citizens in Calgary and throughout Alberta. <br><br>
    <strong>What do you specialize in?</strong> <br>
    Fric, Lowenstein & Co. LLP has several <a class="text-link" routerLink="/areas-of-law">areas of practice</a>, including: <br>
  </blockquote>
  <span class="bullet-point-list">
    <mat-icon class="bullet-point">chevron_right</mat-icon>Real estate – sales, purchases and mortgages<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Wills and estates<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Small business law<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Foreclosures<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Debt collection<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Commercial transactions<br>
    <mat-icon class="bullet-point">chevron_right</mat-icon>Personal Injury Law<br>
  </span>
  <blockquote class="page-writing">
    … and helping people with general legal matters.<br><br>
    <strong>Can I call for a free initial telephone discussion?</strong><br>
    Yes. We will take your initial call free and we will let you know whether we can take your case. <br>
    For areas of law where we do not practice, such as criminal or family law, 
    we work with other lawyers who do and we can refer you to them. 
    If it is a matter that we can take on ourselves, we can 
    give you an estimate of the anticipated costs. 
  </blockquote>
</div>