import { Component, ViewChild } from '@angular/core';
import { NavbarComponent } from './components/navbar';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './app.animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {
  @ViewChild('navbar') private navbar!: NavbarComponent;
  title = 'fl-legal';

  prepareRoute(outlet: RouterOutlet): string | null {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
