import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { env } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentService {

  constructor(private http: HttpClient) { }

  fetchBlogContent(): any {
    // TODO
  }

  fetchFilteredContent(query: string): any {
    console.log(query);
    // TODO
  }
}

// cURL:
// curl --location 'https://api.goperigon.com/v1/all?apiKey=41fe7898-8ec4-4480-9a62-c8347503c98a&q=Real%20Estate%20Legal%20in%20Canada' \
// --header 'x-api-key: YOUR_PERIGON_API_KEY' \
// --data ''

// nodejs + axios:
// const axios = require('axios');
// let data = '';
// let config = {
//   method: 'get',
//   maxBodyLength: Infinity,
//   url: 'https://api.goperigon.com/v1/all?apiKey=41fe7898-8ec4-4480-9a62-c8347503c98a&q=Real Estate Legal in Canada',
//   headers: { 
//     'x-api-key': 'YOUR_PERIGON_API_KEY'
//   },
//   data : data
// };
// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
// })
// .catch((error) => {
//   console.log(error);
// });
