<div class="pricing-container">

  <!-- Sidebar -->
  <aside class="pricing-sidebar">
    <nav>
      <ul class="sidebar-nav">
        <li class="team-member-first"><a (click)="scrollTo('purchase&mortgagefees')">PURCHASE & MORTGAGE FEES</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('cashpurchasefees')">CASH PURCHASE FEES</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('sales')">SALES</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('refinances')">REFINANCES</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('interimfinancing')">INTERIM FINANCING</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('transferofland')">TRANSFER OF LAND</a></li>
        <hr>
        <li class="team-member"><a (click)="scrollTo('corporatefees')">CORPORATE FEES</a></li>
        <hr>
        <li class="team-member-last"><a (click)="scrollTo('wills&powerofattorney&repdocs')">
          WILLS, ENDURING POWER OF ATTORNEY, AND PERSONAL REPRESENTATIVE DOCUMENTS
        </a></li>
      </ul>
    </nav>
  </aside>
  <div class="page-container">
    <h3 class="pricing-banner">Pricing</h3>
    <h4 class="pricing-sub-banner">REAL ESTATE FEES JULY 1, 2024</h4>
    <blockquote class="page-writing" id="purchase&mortgagefees">
      <strong>PURCHASE & MORTGAGE FEES</strong><br>
  
      Under $350,000.00 P&M $650.00 + 325.00 = $975.00 + Disbursements <br>
      $350,000.00 – $600,000.00 P&M $775.00 + $350.00 = $1,125.00 + Disbursements <br>
      $600,000.00 – $850,000.00 P&M $950.00 + $425.00 = $1,375.00 +Disbursements <br>
      OVER $850,000.00 P&M $1,025.00 + $550.00 = $1,575.00 + Disbursements <br>
    </blockquote>
    <blockquote class="page-writing" id="cashpurchasefees">
      <strong>CASH PURCHASE FEES</strong> <br>
  
      Under $400,000.00 = $850.00 + Disbursements<br>
      $400,000.00 to $650,000.00 = $1,150.00 + Disbursements<br>
      $650,000.00 to $850,000.00 = $1,275.00 + Disbursements<br>
      Over $850,000.00 = $1,375.00 and up + Disbursements – Please call for a quote<br>
    </blockquote>
    <blockquote class="page-writing" id="sales">
      <strong>SALES</strong><br>
  
      Under $400,000.00 = $895.00 + Disbursements<br>
      $400,000.00 – $650,000.00 = $995.00 + Disbursements<br>
      $650,000.00 – $950,000 = $1,195.00<br>
      $950,000.00 and above = Call for quote<br>
    </blockquote>
    <blockquote class="page-writing" id="refinances">
      <strong>REFINANCES</strong><br>
  
      $995.00 + Disbursements (includes 1 payout)<br>
      +$175.00 for each additional payout required<br>

    </blockquote>
    <blockquote class="page-writing" id="refinances">
      <strong>INTERIM FINANCING</strong><br>
      $250.00 = Disbursements<br>
    </blockquote>
    <blockquote class="page-writing" id="transferofland">
      <strong>TRANSFER OF LAND</strong><br>
      $375.00 + Land Titles Disbursements<br>
      ASSIGN RENTS:     $225.00 + Land Titles Disbursements<br>
      POA:                              $225.00<br>
      ENCROACHMENT AGREE $250.00 + Disbursements<br>
      RELAXATION $150.00 + Disbursements<br>
    </blockquote>
    <blockquote class="page-writing" id="corporatefees">
      <strong>CORPORATE FEES</strong><br>
      Incorporation of Company<br>
      Standard structure:<br>
      Legal Fees – $475.00<br>
      Filing Fee – $100.00<br>
      Government Filing Fee – $275.00 – tax exempt<br>
      Plus Disbursements and GST<br>
      Professional Corporation:<br>
      Legal Fees – $775.00<br>
      All other charges remain the same as above.<br>
      Annual Returns/Registered Office: $220.00<br>
      Filing Fee – $15.00<br>
      Amalgamations: Base Fee of $1250.00 Plus time and value fee<br>
      Extra provincial registration: $250.00<br>
      Registration of Trade Name: $120.00<br>
    </blockquote>
    <blockquote class="page-writing" id="wills&powerofattorney&repdocs">
      <strong>WILLS, ENDURING POWER OF ATTORNEY, AND PERSONAL REPRESENTATIVE DOCUMENTS</strong><br>
      <strong>SINGLE PERSON</strong> <br>
      Will – $595.00 plus GST<br>
      Enduring Power of Attorney (EPA) – $295.00 plus GST<br>
      Personal Directive (PD) – $275.00 plus GST<br>
      Will and PD or EPA – $750.00 plus GST<br>
      Will, EPA and PD package – $895.00 plus GST<br>
      Codicil – $275.00 plus GST<br><br>

      <strong>MARRIED COUPLE</strong><br>
      Will – $975.00 plus GST<br>
      EPA – $395.00 plus GST<br>
      PD – $350.00 plus GST<br>
      Will and PD or EPA – $1075.00 plus GST<br>
      Will, EPA and PD package – $1175.00 plus GST<br>
      Codicil – $400.00 plus GST<br><br>
      
      <strong>ESTATE PROBATE AND ADMINISTRATION FEES</strong><br>
      This fee is for core services generally required.<br>
      Fee: Estates up to $150,000.00: $2,250.00 plus ½% of the value of the estate;
      plus disbursements<br>
      Estates over $150,000.00: $2,250.00 plus 1% of the value of the estate plus disbursements<br>
      For estates over $1,000,000.00, fees will vary with complexity and size.  Please contact us for a quote.<br><br>
  
      <strong>PERSONAL INJURY CASES</strong><br>
      Usually we charge a contingency fee of 25% to 35% for personal injury cases. <br>
      That is, our fee is a percentage of the amount recovered, 
      and we receive no fee until you are paid.<br><br>
      
      <strong>NOTARY FEES/COMMISSIONING FEES</strong><br>
      One (1) document notarized: $60.00<br>
      Each additional: $5.00 – $20.00<br>
      One (1) certified copy: $35.00<br>
      Each additional: $20.00<br>
      Travel letter in standard form from Government of Canada website: $65.00<br>
      Specific Power of Attorney drafted by our office: $250.00<br>
      One (1) document commissioned: $40.00<br>
      Each additional: $5.00 – $20.00<br><br>

      Other Potential Fees for Extra Services:<br>
      RUSH FEE: $200.00 – $500.00<br>
      EXTRA SIGNING APPOINTMENT FEE: $200.00 / extra appointment<br>
      DIRECT DEPOSIT of Proceeds(Per deposit) $20.00 – $40<br>
      ORDER RPR WITH COMPL $150.00 ORDER COMPLIANCE Only $50.00<br>
      WIRE FEES: Outgoing wire fee: $150.00 Incoming bank wire fee: $35.00<br>
      Mortgages that are FCT or FNF are an extra $250.00<br><br>

      Fees & Disbursements above are estimated. We cannot give a firm amount until we have
      the file opened and have all information and details<br>
    </blockquote>
  </div>
</div>

