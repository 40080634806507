<div class="page-container">
  <h3 class="areas-of-law-banner">Areas of Law</h3>
  <h4 class="areas-of-law-sub-banner">
    Our Mission: To Provide Excellent Legal Service at a Reasonable Cost
  </h4>
  <blockquote class="page-writing">
    For more than 30 years, clients in Calgary and throughout Alberta have trusted Fric, Lowenstein & Co. LLP 
    for prudent, customer-focused legal representation. No matter what your specific legal needs may be, 
    our analytical team will work tirelessly to defend your rights. 
    <a class="text-link" routerLink="../contact-us">Contact our team</a> 
    to discuss your specific situation. We help people with services related to:
  </blockquote>
  <mat-list class="properties-list">
    <mat-list-item class="property-list-item-first">
      Real Estate Transactions & Mortages
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Wills & Estates
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Civil Litigation
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Personal Injury
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Running a Small Business
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Sales & Purchases of Businesses
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Employment Law - Including Wrongful Termination
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Builders’ Liens
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Foreclosures
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Debt Collection
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Commercial transactions
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Notarization
    </mat-list-item>
    <mat-list-item class="property-list-item">
      Banking
    </mat-list-item>
    <mat-list-item class="property-list-item-last">
      Private Lending
    </mat-list-item>
  </mat-list>
</div>