import { Component } from '@angular/core';
import { env } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  officeName: string = env.maps.pointOfInterest || 'Southcentre Exceutive Tower';
  officeLong: number = env.maps.longitude || -114.06998756735986;
  officeLat: number = env.maps.latitude || 50.955083281651376;
}
