<div class="carousel">
  <div class="carousel-indicators">
    <button
      *ngFor="let image of images; let i = index"
      [class.active]="i === currentSlide"
      (click)="currentSlide = i; resetSlideShow()"
    >
    </button>
  </div>

  <div class="carousel-inner">
    <div
      *ngFor="let image of images; let i = index"
      class="carousel-item"
      [class.active]="i === currentSlide"
    >
      <img [src]="image" class="d-block w-max" alt="Slide {{ i + 1 }}" />
    </div>
  </div>

  <!-- Controls -->
  <a class="carousel-control-prev" role="button" (click)="previousSlide()">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" role="button" (click)="nextSlide()">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>