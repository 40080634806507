export const env = {
  production: true,
  name: 'prod',
  firebase: {
    apiKey: "AIzaSyDQ3R1_Ei4lKBQ6ENnyHkEoeLVXaAfL7So",
    authDomain: "fl-legal.firebaseapp.com",
    databaseURL: "https://fl-legal-default-rtdb.firebaseio.com",
    projectId: "fl-legal",
    storageBucket: "fl-legal.firebasestorage.app",
    messagingSenderId: "581710329723",
    appId: "1:581710329723:web:d080beb8b2d70567bf6fde",
    measurementId: "G-SFSMDQYZMR"
  },
  maps: {
    apiKey: "AIzaSyAsucFozvuHXMfyfWgb_Y6qpgu-S0wTWQ4",
    latitude: 50.955083281651376, 
    longitude: -114.06998756735986,
    pointOfInterest: "Southcentre Executive Tower"
  },
  perigon: {
    apiKey: "41fe7898-8ec4-4480-9a62-c8347503c98a",
    baseApiUrl: "https://api.goperigon.com/v1/all",
    baseContentUrl: "https://api.goperigon.com/v1/all?apiKey=41fe7898-8ec4-4480-9a62-c8347503c98a&q=Real%20Estate%20Legal%20in%20Canada"
  }
};