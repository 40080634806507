<div class="page-container">
  <h3 class="home-banner">Welcome</h3>
  <h4 class="home-sub-banner">FRIC, LOWENSTEIN & CO. LLP: Serving Calgary For Over 30 Years...</h4>

  <blockquote class="page-writing">
    Turn to Fric, Lowenstein & Co. LLP for highly <a class="text-link" routerLink="../areas-of-law">experienced legal representation.</a>
    Serving clients in Calgary and throughout Alberta, Fric, Lowenstein & Co. LLP offers a wide
    range of areas of expertise for individuals and businesses throughout Alberta.
    Trust the <a class="text-link" routerLink="../about-us">dynamic team</a> from 
    Fric, Lowenstein & Co. LLP for comprehensive legal representation.

    Personalized Legal Services in Calgary
    
    No matter what your specific legal challenges may be, choose Fric, Lowenstein & Co.
    LLP and place 3 decades of legal expertise on your side. Legal challenges can be a
    stressful and difficult time for you, your family or your business, but by choosing 
    Fric, Lowenstein & Co. LLP, you can rely on legal services completely customized to your specific needs.
  </blockquote>

  <mat-list class="services-list">
    <mat-list-item class="service-list-item-first">Real Estate Transactions & Mortgages</mat-list-item>
    <mat-list-item class="service-list-item">Wills & Estates</mat-list-item>
    <mat-list-item class="service-list-item">Civil Litigation</mat-list-item>
    <mat-list-item class="service-list-item">Personal Injury</mat-list-item>
    <mat-list-item class="service-list-item">Business Incorporation</mat-list-item>
    <mat-list-item class="service-list-item">Business Sales & Purchases</mat-list-item>
    <mat-list-item class="service-list-item">Employment Law - including wrongful dismissal</mat-list-item>
    <mat-list-item class="service-list-item">Builders’ Liens</mat-list-item>
    <mat-list-item class="service-list-item-last">…and helping people with general legal matters</mat-list-item>
  </mat-list>
  <app-carousel></app-carousel>
  <footer>
    We offer <a class="text-link" routerLink="../pricing">discounted flat rates</a> for many routine legal services.
    Now Located at <strong>Southcenter Executive Tower at 11012 Macleod Trail SE, Calgary, AB</strong>
    Easy to get to and easy to park!
  </footer>
</div>
